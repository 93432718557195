@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

* {
  font-family: 'initial';
}

.navbar {
  padding-top: 20px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  max-width: 780px;
  margin: 0 auto;
  border-bottom: 2px solid #e0e0e0;
}

.navbar h1 {
  color: rgb(91, 91, 91);
}

.navbar .links {
  margin-left: auto;
}

.navactive {
  color: #4cdce9 !important;
}

.navbar a {
  margin-left: 16px;
  text-decoration: none;
  color: #3b3b3b;
  padding: 6px;
  font-family: 'emoji';
}

.navbar a:hover {
  color: #4cdce9;
}

.content {
  max-width: 780px;
  margin: 10px auto;
  padding: 2px;
}

@media (max-width: 600px) {
  .navbar {
    max-width: 380px;
  }
  .content {
    max-width: 350px;
  }
}